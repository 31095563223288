<template>
    <div>
      <the-app-bar  />
      <Register/>
      <Footer />
    </div>
  </template>
  
  <script>
import Login from '../../../components/client/login.vue';
import Register from '../../../components/client/Register.vue';
  import Footer from "../../../components/footer.vue";
  import TheAppBar from "../../../components/NavBar/TheAppBar.vue";
  export default {
    components: { TheAppBar, Footer, Register }
  };
  </script>
  
  