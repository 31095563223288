<template>
  <div class="font-montserrat bg-main top">
    <div class="card">
      <div class="row ">

        <div class="col-md-6 col-sm-12 pb-5 px-5">

          <div class="form-group text-center mb-3">
            <div class="container">
              <div class="py-8">
                <!-- If you wish to reference an existing file (i.e. from your database), pass the url into imageData() -->
                <div class="file-input flex items-center">
                  <!-- Preview Image -->
                  <div class="h-20 w-20 rounded-full overflow-hidden bg-white">
                    <!-- Placeholder image -->
                    <div v-if="!url">
                      <svg class="h-full w-full text-white-300" fill="currentColor" viewBox="0 0 24 24">
                        <path
                          d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </div>
                    <!-- Show a preview of the photo -->
                    <div v-if="url" class="h-20 w-20 rounded-full overflow-hidden">
                      <img :src="url" alt="" class="h-20 w-20 object-cover" />
                    </div>
                  </div>

                  <div class="flex items-center">
                    <!-- File Input -->
                    <div class="ml-5 rounded-md shadow-sm">
                      <!-- Replace the file input styles with our own via the label -->
                      <input @change="handleImage" x-ref="input" type="file" accept="image/*,capture=camera"
                        name="photo" id="photo" class="custom" />
                      <label for="photo"
                        class="py-2 px-3 border border-gray-300 rounded-md text-sm leading-4 font-medium text-gray-700 hover:text-indigo-500 hover:border-indigo-300 focus:outline-none focus:border-indigo-300 focus:shadow-outline-indigo active:bg-gray-50 active:text-indigo-800 transition duration-150 ease-in-out">
                        Upload Profile Photo
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="firstname">First Name</label>
            <input type="text" v-model="firstName" class="form-control" />
            <small class="text-danger" v-if="FNerrMsg">{{ FNerrMsg }}</small>
          </div>

          <div class="form-group">
            <label for="lastname">Last Name</label>
            <input type="text" v-model="lastName" class="form-control" />
            <small class="text-danger" v-if="LNerrMsg">{{ LNerrMsg }}</small>
          </div>

          <div class="form-group">
            <label for="email">email</label>
            <input type="text" v-model="email" class="form-control" />
            <small class="text-danger" v-if="emailMsg">{{ emailMsg }}</small>
          </div>

          <div class="form-group ">
            <label for="firstName"> Phone Number</label>
            <input type="button" data-toggle="modal" :value="phone" data-target="#businesPhonModalCenter"
              class="form-control text-left" style="background-color: #e8f0fe;" />
          </div>

          <!-- Modal -->
          <div class="modal fade" id="businesPhonModalCenter" tabindex="-1" role="dialog"
            aria-labelledby="businesPhonModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
              <div class="modal-content pb-5">
                <div class="modal-header">
                  <h5 class="modal-title mx-5" id="businesPhonModalLongTitle">
                    Verify Phone Number
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body space-y-5">
                  <div v-if="show_confirm == 1">
                    <div class="row flex justify-content-center">
                      <div class="col-md-10">
                        <div class="form-group">
                          <label for="countries">Select country code</label>
                          <select v-model="callCode" class="form-control" id="">
                            <option :value="item" v-for="item in all_countries" :key="item.id">{{ item.fullName }}
                            </option>
                          </select>
                        </div>
                        <div class="form-group">
                          <input type="tel" v-model="phonenumber" class="form-control" />
                        </div>

                        <button @click.prevent="sendCode" class="btn btn-block btn-primary">
                          <b-spinner v-if="isloading" type="grow"></b-spinner>
                          <span v-else>send code</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-if="show_confirm == 2">
                    <div class="row flex justify-content-center">
                      <div class="col-md-10">
                        <div class="form-group">
                          <input type="text" placeholder="xxxxxx" class="form-control" v-model="confirm_otp" />
                        </div>
                        <button @click.prevent="verifyCode" class="btn btn-block btn-primary">
                          <b-spinner v-if="isloading" type="grow"></b-spinner>
                          <span v-else>verify</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" v-model="password" class="form-control" />
            <small class="text-danger" v-if="passwordMsg">{{
              passwordMsg
              }}</small>
          </div>

          <div class="form-group">
            <label for="password">Confirm Password</label>
            <input type="password" v-model="confirmPassword" class="form-control" />
          </div>

          <button @click.prevent="SignUp" @keydown.enter="SignUp" type="button" class="btn btn-primary btn-block"
            :disabled="!isDisbaled">
            <b-spinner v-if="loading" type="grow"></b-spinner>
            <span v-else>Register</span>
          </button>
          <div class="mt-3 mx-2">
            Already have account?
            <router-link to="/account/client/login">Login</router-link>
          </div>
        </div>

        <div class="d-none d-lg-flex  col-md-6 rightside">

        </div>
      </div>
    </div>

  </div>
</template>

<!-- TO DO: change profile image url -->

<script>
/* eslint-disable no-mixed-spaces-and-tabs */
import { mapGetters } from "vuex";
import $ from "jquery";

export default {
  data() {
    return {
      loading: false,
      isloading: false,
      firstName: "",
      lastName: "",
      password: "",
      url: null,
      email: "",
      account_info: JSON.parse(localStorage.getItem("account_info")),
      logo: null,
      callCode: "",
      phonenumber: "",
      show_confirm: 1,
      phone: "",
      phoneNumberGUID: "",
      confirm_otp: "",
      confirmPassword: "",

      FNerrMsg: "",
      LNerrMsg: "",
      emailMsg: "",
      phoneMsg: "",
      passwordMsg: "",
      IsAccountUpgrade:false
    };
  },

  components: {},

  mounted() {
    this.$store.dispatch("dashboard/GetAllCountries");
  },

  computed: {
    ...mapGetters("foodhub", ["supercategories"]),
    ...mapGetters("dashboard", ["all_countries"]),
    isDisbaled() {
      return (
        this.firstName.length > 0 &&
        this.lastName.length > 0 &&
        this.email.length > 0 &&
        this.password.length > 0
      );
    }
  },

  methods: {
    sendCode() {
      let user_phone = this.phonenumber;
      if (user_phone.charAt(0) == 0 || user_phone.charAt(0) == 1) {
        var userPhone = user_phone.substring(1);
      } else {
        userPhone = user_phone;
      }
      this.phonenumber = `${this.callCode.callCode}${userPhone}`;

      const data = {
        OTPReasonId: 40,
        UserTypeId: 1,
        PhoneNumber: this.phonenumber,
        Email: "",
        CallCode: this.callCode.callCode,
        OTP: ""
      };
      this.isloading = true;
      this.$store
        .dispatch("default/sendOTP", data)
        .then((response) => {
          if (response.status == 200) {
            this.isloading = false;
            this.$swal({
              icon: "success",
              title: "Success",
              text: "OTP Sent successfully"
            });
            this.show_confirm = 2;
          }
        })
        .catch((err) => {
          this.isloading = false;
          $("#businesPhonModalCenter").modal("hide");
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    },
    verifyCode() {
      const payload = {
        OTPReasonId: 40,
        UserTypeId: 1,
        PhoneNumber: this.phonenumber,
        Email: "",
        CallCode: this.callCode.callCode,
        OTP: this.confirm_otp
      };
      this.isloading = true;
      this.$store
        .dispatch("default/verifyOTP", payload)
        .then((response) => {
          if (response.status == 200) {
            this.isloading = false;
            this.phoneNumberGUID = response.data;
            $("#businesPhonModalCenter").modal("hide");
            this.show_confirm = 1;
            this.phone = this.phonenumber;
            this.$swal({
              icon: "success",
              title: "Success",
              text: "phone number confirmed successfully"
            });
          }
        })
        .catch((err) => {
          this.isloading = false;
          this.$swal({
            icon: "error",
            title: "Oops...",
            text: err.response.data
          });
        });
    },
    handleImage(e) {
      this.logo = e.target.files[0];
      this.url = URL.createObjectURL(this.logo);
    },

    SignUp() {
      if (!this.firstName) {
        this.FNerrMsg = "First Name is required";
      }
      if (!this.lastName) {
        this.LNerrMsg = "Last Name is required";
      }
      if (!this.email) {
        this.emailMsg = "Email is required";
      }

      if (!this.phonenumber) {
        this.phoneMsg = "Phone number is required";
      }

      if (!this.password) {
        this.passwordMsg = "Password is required";
      }

      if (this.password !== this.confirmPassword)
        return this.$swal({
          icon: "error",
          title: "Oops...",
          text: "Password don't match"
        });

      const formData = new FormData();
      formData.append("firstname", this.firstName);
      formData.append("lastname", this.lastName);
      formData.append("email", this.email);
      formData.append("password", this.password);
      formData.append("countryId", this.callCode.id);
      formData.append("phoneNumber", this.phonenumber);
      formData.append("logo", this.logo);
      formData.append("IsAccountUpgrade", this.IsAccountUpgrade);


      const payload = {
        data: formData,
        phoneNumberGUID: this.phoneNumberGUID
      };

      //   for (var pair of payload.data.entries()) {
      //     console.log(pair[0] + ", " + pair[1]);
      //   }

      //   console.log(payload.phoneNumberGUID);

      this.loading = true;
      this.$store
        .dispatch("default/CreateAccount", payload)
        .then((res) => {
          console.log(res)
          if (res.status == 200) {
            this.loading = false;
            this.$swal({
              icon: "success",
              title: "Success",
              text: "Account Created successfully"
            });
            this.$router.push("/account/client/login");
          }
        })
        .catch((err) => {
          if (err.response.data == 1250) {
            this.IsAccountUpgrade = true;
            this.$swal({
              icon: "success",
              title: "Infomation",
              text:
                "You already have an account with us! An oonpay use made a purchase on your behalf. We will update your existing details with this newer details. Click on Register button"
            });
          }
          console.log("Error message",)
          this.loading = false;
          err.response.data.map((el) =>
            this.$swal({
              icon: "error",
              title: "Oops...",
              text: el.error
            })
          );
        });
    }
  }
};
</script>

<style scoped>
input[type="file"].custom {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  white-space: nowrap;
  width: 1px;
}

.rightside {
  background-image: url('../../assets/auth/bg20.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}

.bg-main {
  background: #f3f3f3;
}

.oonpay-bg {
  background: #0c4369;
}

@media screen and (max-width: 720px) {
  .oonpay-row {
    flex-direction: column-reverse;
    margin: 5rem 0;
  }
  .top {
    margin-top: -80px;
  }
}
</style>
